import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {Box, Grid, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {sectionSpacing, TEXT_PADDING} from "../theme";


export default () => {
  return (
    <>
      <Toolbar />
      <Container
        maxWidth={'md'}
      >
        <Box
          marginY={sectionSpacing}
          textAlign={'center'}
        >
          <Typography
            variant={'subtitle1'}
            fontWeight={'bold'}
            textTransform={'uppercase'}
          >
            Duurzaam
          </Typography>
          <Typography variant={'h3'}>
            Samen werken aan een <Box component={'span'} color={'primary.main'}>duurzame buurt</Box>
          </Typography>
          <Typography paragraph>
            De Schelpenbuurt is een wijk waar de bewoners aandacht hebben voor het veranderende klimaat, voor de natuur en voor het opwekken en gebruiken van groene energie. Bij de bouw en aanleg van de wijk is al zoveel mogelijk gebruik gemaakt van duurzame materialen. In uw woning en in uw leefomgeving. U woont hier dan ook met een vooruitziende blik naar de toekomst. Daarmee is de Schelpenbuurt een unieke woonlocatie. Iedereen doet wat, thuis en in de buurt. In deze groene wijk kunt u, als nieuwe bewoner, samen met uw wijkgenoten uw bijdrage leveren door een stap te zetten richting een duurzame(re) toekomst!
          </Typography>
        </Box>
      </Container>
      <Container
        maxWidth={'lg'}
      >
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Box
                paddingRight={{md: TEXT_PADDING}}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Woning voor de toekomst
                </Typography>
                <Typography variant={'body2'} paragraph>
                  In de Schelpenbuurt is gekozen voor een betekenisvol duurzaam woningontwerp. Dit betekent bijvoorbeeld dat uw nieuwe woning wordt verwarmd door de aansluiting op een duurzaam warmtesysteem. Uw elektriciteit wekt u zelf op met de zonnepanelen die op het dak liggen. Bij de bouw van de woningen is ook rekening gehouden met klimaatveranderingen. In een langdurige warme periode zorgen bouwkundige aanpassingen ervoor dat de hitte in de woning zoveel mogelijk beperkt blijft. Mocht er een heftige regenbui vallen, dan wordt binnendringend water voorkomen door het hoge vloerpeil van de woning.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/GettyImages-1215302438@2x.jpg'}
                alt={'fietser'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
              order={{md: 2}}
            >
              <Box
                paddingLeft={{md: TEXT_PADDING}}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Help mee waar het kan
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Ook voor u als toekomstige bewoner in de Schelpenbuurt is het belangrijk om rekening te houden met klimaatverandering. Zoals met periodes van hitte en droogte, afgewisseld met hevige regenbuien. Dit kan door de inrichting van de ruimte bij en om uw huis aan te passen. Zo zorgen bomen voor beschutting tegen de zon en voor koelte in huis. Ook helpt het om niet teveel tegels in de tuin te leggen. Dan kan het hemelwater makkelijker de bodem indringen en is er meer ruimte voor groen, wat weer goed is voor de bloemetjes en de beestjes.
                </Typography>
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Win-win voor de toekomst
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Binnen de wijk is zoveel mogelijk gebruikgemaakt van gerecyclede materialen. Ook zijn de woningen gebouwd met aandacht voor CO2-arme productieprocessen. Ook voor uw woning zijn dit belangrijke aandachtspunten geweest en is hier in hoge mate op ingezet. Zonder dat u het merkt heeft u met deze woning al een grote stap gezet richting duurzaam wonen.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/kinderen.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Box
                paddingRight={{md: TEXT_PADDING}}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Duurzame woning in een groene leefomgeving
                </Typography>
                <Typography variant={'body2'} paragraph>
                  De Schelpenbuurt ligt eigenlijk op een eiland. U kunt dus heerlijk genieten van het uitzicht op en over het water maar ook van een rijke diversiteit aan bloemen, planten, vogels en insecten.
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Door het openbaar groen goed in te richten en door de aanwezigheid van het water, worden de effecten van de klimaatverandering in de openbare ruimte zoveel mogelijk beperkt. Uw duurzame woning sluit hier dus perfect op aan.
                </Typography>
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Samen dat extra stapje zetten
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Als we onze leefstijl willen verduurzamen, kunnen we dat individueel doen, maar leuker is om het samen met de hele buurt op te pakken. Bijvoorbeeld door een deelauto te gebruiken, gereedschap met elkaar te delen, gezamenlijke maaltijden te organiseren of met elkaar een moestuin of boomgaard te starten in de openbare ruimte. Zo wordt de Schelpenbuurt een gezellige buurt waar duurzaamheid belangrijk is en je elkaar stimuleert om net dat stapje extra te zetten.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/GettyImages-1174001922@2x.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
